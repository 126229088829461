/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import ThemeContext from '../context/context.theme';
import Header from './component.header';
import Newsletter from './component.newsletter';
import Footer from './component.footer';
import Notifications from './component.notifications';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    const theme = useContext(ThemeContext);

    const ref = useRef(null);

    useEffect(() => {
        if (theme.notification) {
            // pass the notification to component
            ref.current(theme.notification);
            // reset the notification
            theme.setNotification(null);
        }
    }, [theme.notification]);

    const wrapperClass = () => {
        const classes = [];

        if (theme.menuOpen) {
            classes.push(`navigation-${theme.screenType} navigation-${theme.screenType}--active`)
        } else {
            classes.push(`navigation-${theme.screenType}`);
        }

        if (theme.loading) {
            classes.push('u-loading');
        }

        return classes.join(' ');
    }

    return (
        <div className={wrapperClass()}>
            <Notifications children={add => (ref.current = add)} />
            <Header siteTitle={data.site.siteMetadata.title} />
            <main>{children}</main>
            <Newsletter />
            <Footer />
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;

