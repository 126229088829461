import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const Footer = () => {
    const footerData = useStaticQuery(graphql`
        query footer {
            wordpress {
                globalContent(idType: SLUG, id: "footer") {
                    ACFFooterBlockFields {
                        content
                        title
                        discoverLinks {
                            link {
                                url
                                title
                            }
                        }
                        helpLinks {
                            link {
                                url
                                title
                            }
                        }
                        productLinks {
                            link {
                                url
                                title
                            }
                        }
                        technologyLinks {
                            link {
                                url
                                title
                            }
                        }
                        socialMediaLinks {
                            icon
                            link {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = footerData.wordpress.globalContent.ACFFooterBlockFields;

    return (
        <footer className="c-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <span className="d-block u-text--bold xs-mb-10">{content.title}</span>
                        <div dangerouslySetInnerHTML={{ __html: content.content }}></div>

                        <ul className="c-social-share">
                            {content.socialMediaLinks.map(({ link, icon }, index) => (
                                <li key={index} className="c-social-share__item">
                                    <a className="c-social-share__link" href={link.url} aria-label={`View our ${icon}`}>
                                        <i className={`c-social-share__icon fab fa-${icon}`}></i>
                                    </a>
                                </li>
                            ))}
                        </ul>

                        <hr className="xs-mb-30 xs-mt-30 d-lg-none d-xl-none" />
                    </div>

                    <div className="offset-lg-1 col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">Products</h4>
                            <ul className="c-sub-nav__list">
                                {content.productLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={link.url}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">Technology</h4>
                            <ul className="c-sub-nav__list">
                                {content.technologyLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={link.url}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">Help</h4>
                            <ul className="c-sub-nav__list">
                                {content.helpLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={link.url}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>

                    <div className="col-6 col-lg-2">
                        <div className="c-sub-nav">
                            <h4 className="c-sub-nav__title">Discover</h4>
                            <ul className="c-sub-nav__list">
                                {content.discoverLinks.map(({ link }) => {
                                    if (link) {
                                        return (
                                            <li key={link.title} className="c-sub-nav__item">
                                                <Link className="c-sub-nav__link" to={link.url || "/"}>
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return false;
                                })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="c-bottom-links">
                    <span className="c-bottom-links__copyright">
                        &copy; {new Date().getFullYear()} {(process.env.GATSBY_THEME == "King" ? "King" : "WINTECH")} Racing. All rights reserved. <a href="https://www.absolute-design.co.uk" target="_blank" rel="noopener noreferrer">Website by Absolute</a>
                    </span>
                    <ul className="c-bottom-links__list">
                        <li className="c-bottom-links__item">
                            <Link className="c-bottom-links__link" to="/terms-and-conditions/">Privacy policy</Link>
                        </li>
                        <li className="c-bottom-links__item">
                            <Link className="c-bottom-links__link" to="/terms-and-conditions/">Terms and conditions</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

