import React, { useRef }  from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import kingLogo from '../assets/images/king-racing-shells.svg';
import wintechLogo from '../assets/images/wintech-racing.svg';

import TopLinks from './component.topLinks';
import Nav from './nav/component.nav';
import NavQuickLinks from './nav/component.nav-quicklinks';

const Header = ({ siteTitle }) => {
    const headerEl = useRef(null);
    return (
        <>
            <header ref={headerEl} className="c-header">
                <TopLinks/>
        
                <div className="c-header__main">
                    <div className="container">
                        <div className="c-header__items">
                            <Link className="c-header__logo" to="/">
                                <img src={(process.env.GATSBY_THEME == "King" ? kingLogo : wintechLogo)} alt="Wintech Racing"/>
                            </Link>
                            <Nav headerHeight={headerEl}/>
                        </div>
                    </div>
                </div>
            </header>
            <NavQuickLinks />
        </>
    );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
