import { useStaticQuery, graphql } from 'gatsby';

const useMenuData = () => {
    const menuData = useStaticQuery(graphql`
        query Menu {
            wordpress {
                globalContents(where: {name: "Navigation"}) {
                    nodes {
                        ACFNavigationBlockFields {
                            navigationLink {
                                class
                                link {
                                    url
                                }
                                title
                                subTitle
                                imageLinks {
                                    title
                                    link {
                                        url
                                    }
                                    image {
                                        altText
                                        sourceUrl
                                        imageFile {
                                            childImageSharp {
                                                thumbnail: fixed(width: 253, height: 118) {
                                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                                }
                                            }
                                        }
                                    }
                                }
                                textLinks {
                                    title
                                    link {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return menuData.wordpress.globalContents.nodes[0].ACFNavigationBlockFields.navigationLink;
};

export default useMenuData;
