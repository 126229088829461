import React, { useContext, useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';

import Logo from '../assets/images/leading-edge.svg';
import KingLogo from '../assets/images/king-footplate-shield.svg';

let newsletterLogo = Logo;
let newsletterAltText = 'WinTech Racing Logo';
if(process.env.GATSBY_THEME == "King") {
    newsletterLogo = KingLogo;
    newsletterAltText = 'King Racing Logo';
}

import ThemeContext from "../context/context.theme";
import Button from '../components/component.button';

const Newsletter = () => {
    const theme = useContext(ThemeContext);
    const defaultFormDetails = {
        email: '',
    };
    const [formDetails, setFormDetails] = useState(defaultFormDetails);
    
    const handleInputChange = event => {
        const { target } = event;
        const { value, name } = target;
        
        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };
    
    const handleSubmit = async event => {
        event.preventDefault();
    
        addToMailchimp(formDetails.email, { 'PAGEID': 'Newsletter' })
            .then(data => {
                if (data.result === "success") {
                    // clear input
                    setFormDetails({ email: '' });
                    // show notification
                    theme.setNotification({
                        message: 'Thanks',
                        timestamp: Date(),
                        success: true
                    });
                } else {
                    theme.setNotification({
                        message: data.msg,
                        timestamp: Date(),
                        success: false
                    });
                }
        });
    };
    
    return (
        <div className="u-bg--mercury">
            <div className="container">
                <section className="c-newsletter">
                    <div className="c-newsletter__intro">
                        <img className="c-newsletter__logo" src={newsletterLogo} alt={newsletterAltText} />
                        <div className="c-newsletter__title-wrapper">
                            <span className="c-newsletter__title">Get ahead with email updates</span>
                            <span className="c-newsletter__subtitle">Receive the latest product updates and {(process.env.GATSBY_THEME == "King" ? "KING" : "WINTECH")} Racing news</span>
                        </div>
                    </div>
                    <div className="c-newsletter__signup">
                        <form className="c-newsletter__form" onSubmit={handleSubmit}>
                            <div className="c-newsletter__holder c-input-holder">
                                <input
                                    className={formDetails.email.length
                                        ? "c-newsletter__input c-input-holder__input c-input-holder__input--dark c-input-holder__input--has-content"
                                        : "c-newsletter__input c-input-holder__input c-input-holder__input--dark"
                                    }
                                    type="text"
                                    id="newsletterEmail"
                                    name="email"
                                    value={formDetails.email}
                                    onChange={handleInputChange}
                                    required
                                />
                                <label className="c-newsletter__label c-input-holder__label" htmlFor="newsletterEmail">
                                    {theme.screenType === 'desktop' ? "Enter your email address" : "Email address"}
                                </label>
                            </div>
                            <Button className="c-newsletter__button" small text="Sign up" />
                        </form>
                        <Link className="c-newsletter__additional-link" to="/terms-and-conditions/">View our privacy policy</Link>
                    </div>
                </section>
            </div>
        </div>
    );
}



export default Newsletter;
