import React from 'react';
import { Link } from 'gatsby';

let topLinkClass = 'u-bg--dove-grey';
if(process.env.GATSBY_THEME == "King") {
    topLinkClass = 'u-bg--primary';
}

import kingLogoWhite from '../assets/images/king-racing-shells-white.svg';
import kingLogoBlack from '../assets/images/king-racing-shells.svg';
import wintechLogoWhite from '../assets/images/wintech-racing-white.svg';
import wintechLogoBlack from '../assets/images/wintech-racing-black.svg';

const TopLinks = () => (
    <div className={topLinkClass}>
        <div className="container">
            <div className="c-top-links">

                <ul className="c-top-links__list language__switcher">
                </ul>

                <ul className="c-top-links__switcher">
                    <li className={(process.env.GATSBY_THEME != "King" ? 'active' : '') + ' switcher-wintech'}><a href={(process.env.GATSBY_THEME != "King" ? '/' : 'https://wintechracing.com/')}><img src={(process.env.GATSBY_THEME == "King" ? wintechLogoWhite : wintechLogoBlack)} alt="WinTech Racing"/></a></li>
                    <li className={(process.env.GATSBY_THEME == "King" ? 'active' : '') + ' switcher-king'}><a href={(process.env.GATSBY_THEME == "King" ? '/' : 'https://www.kingracingshells.com/')}><img src={(process.env.GATSBY_THEME == "King" ? kingLogoBlack : kingLogoWhite)} alt="King Racing"/></a></li>
                </ul>

                <ul className="c-top-links__list">
                    <li className="c-top-links__item">
                        <Link className="c-top-links__link" to="/contact/">Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default TopLinks;