import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';

import useMenuData from '../../hooks/hook.useMenuData';
import ThemeContext from '../../context/context.theme';
import NavSub from './component.nav-sub';
import Search from '../component.search';

const Nav = ({ headerHeight }) => {
    const theme = useContext(ThemeContext);
    const menu = useMenuData();

    // Set top level menu data to 'menu' variable
    const [hovered, setHovered] = useState({});

    /**
     * Keeps track of hovered menu items
     *
     * @param {*} menuItem
     * @param {*} hovered
     */
    function toggleHover(menuItem, hovered) {
        setHovered({
            [menuItem]: hovered
        });

        // @todo make mobile/desktop use the same toggle functionality
        // theme.toggleMenu();
        // console.log(`Toggle Hover - ${Date()}`);
    }

    /**
     * Compile menu classes by item
     *
     * @param {*} { title, hasChildren }
     * @returns
     */
    function setMenuClass({ title, hasChildren }) {
        const classes = ['c-nav__item'];

        // Checks the hovered state by the current item adding a hover class if state is true
        if (hovered[title]) classes.push('c-nav__item--active');

        // Sets child menu class if hasChildren set
        if (hasChildren) classes.push('c-nav__item--has-subnav');

        return classes;
    }

    return (
        <nav className="c-nav" aria-label="Main menu">
            <span className="c-nav__search xs-pr-30">
                <Search headerHeight={headerHeight} />
            </span>

            <button className={theme.menuOpen ? "c-nav-toggle c-nav-toggle--active" : "c-nav-toggle"} onClick={theme.toggleMenu} aria-expanded="false" aria-controls="mainMenu">
                <span className="u-visually-hidden">Menu</span>
                <span className="c-nav-toggle__container">
                    <span className="c-nav-toggle__inner"></span>
                </span>
            </button>

            <ul className={theme.menuOpen ? "c-nav__list c-nav__list--active" : "c-nav__list"} id="mainMenu" aria-hidden="false">
                {menu.map(({ title, ...menuItem }, index) => {
                    const hasChildren = (menuItem.imageLinks || menuItem.textLinks) ? true : false;
                    const classes = setMenuClass({ title, hasChildren });
                    const shouldHover = theme.screenType === "desktop" && hasChildren;

                    /*
                        For mobile renders a span rather than a link to stop the click causing the page link to be followed
                    */
                    return (
                        <li
                            onMouseEnter={shouldHover ? () => toggleHover(title, true) : null}
                            onMouseLeave={shouldHover ? () => toggleHover(title, false) : null}
                            onClick={theme.screenType === "mobile" ? () => toggleHover(title, true) : null}
                            key={index}
                            className={classes.join(' ')}
                            aria-haspopup={hasChildren}
                        >
                            {(!hasChildren || (hasChildren && theme.screenType !== "mobile")) && (
                                <Link 
                                    className={menuItem.class ? `c-nav__link ${menuItem.class}` : `c-nav__link`}
                                    to={menuItem.link && menuItem.link.url}
                                    onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}
                                >
                                    {title}
                                </Link>
                            )}

                            {hasChildren && theme.screenType === "mobile" && (
                                <span className="c-nav__link">{title}</span>
                            )}

                            {hasChildren && (
                                <NavSub
                                    back={(e) => {
                                        e.stopPropagation();
                                        toggleHover(title, false);
                                    }}
                                    headerHeight={headerHeight}
                                    topLevelTitle={title}
                                    topLevelSlug={menuItem.link.url}
                                    images={menuItem.imageLinks}
                                    links={menuItem.textLinks}
                                    subTitle={menuItem.subTitle}
                                    active={hovered[title]}
                                />
                            )}
                        </li>
                    );
                })}
                <li className="c-nav__item c-nav__item--search">
                    <Search headerHeight={headerHeight} />
                </li>
            </ul>
        </nav>
    );
};

export default Nav;
