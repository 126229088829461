import React, { useContext } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import ThemeContext from '../../context/context.theme';
import Button from '../component.button';

const NavSub = ({ images, links, subTitle, active, headerHeight, back, topLevelTitle, topLevelSlug }) => {
    const theme = useContext(ThemeContext);
    const defaultMenuImage = useStaticQuery(graphql`
        query defaultMenuImage {
            wintechFile: file(relativePath: { eq: "default-post-image.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 253, height: 118) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-post-image-king.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 253, height: 118) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    // Used to position the submenu to the top of the screen or below the header dependent on the resolution
    let top = 0;

    if (theme.screenType === 'desktop') {
        top = headerHeight && headerHeight.current && headerHeight.current.clientHeight;
    }

    let defaultPostImageSrc = defaultMenuImage.wintechFile;
    if(process.env.GATSBY_THEME == "King") {
       defaultPostImageSrc = defaultMenuImage.kingFile; 
    }

    return (
        <div style={{ top }} className={active ? "c-nav__sub c-nav__sub--active" : "c-nav__sub"} aria-hidden={!active}>
            <div className="container">
                <a className="c-nav__back" onClick={back}>
                    <i className="fal fa-chevron-left"></i> Back
                </a>

                <div className="row">
                    <div className="col-lg-9">
                        <div className="c-nav__sub-section">
                            <ul className="c-nav__image-list">
                                {images && images.map((imageItem, index) => (
                                    <li key={index} className="c-nav__image-item">
                                        <Link to={imageItem.link.url} tabIndex="-1" className="c-nav__image-link" onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                            <Img
                                                className="c-nav__image-image"
                                                fixed={imageItem.image ? imageItem.image.imageFile.childImageSharp.thumbnail : defaultPostImageSrc.childImageSharp.thumbnail}
                                                alt={imageItem.image ? imageItem.image.altText : "WinTech Racing logo"}
                                            />
                                            <span className="c-nav__image-title">{imageItem.title}</span>
                                        </Link>
                                    </li>
                                ))}
                                {topLevelTitle && (
                                    <li key={topLevelSlug} className="c-nav__all">
                                        <Link className="c-nav__all-link" to={topLevelSlug} onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                            {`View all ${topLevelTitle}`} <i className="c-nav__all-icon fal fa-chevron-right"></i>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="c-nav__sub-section">
                            <span className="c-nav__title" dangerouslySetInnerHTML={{ __html: subTitle }}></span>

                            <ul className="c-nav__text-list">
                                {links && links.map((link, index) => {
                                    // Provides a fallback if a link URL/slug has not been set
                                    const slug = link.link ? link.link.url : '';

                                    return (
                                        <li key={index} className="c-nav__text-item">
                                            <Link to={slug} tabIndex="-1" className="c-nav__text-link" onClick={theme.screenType === "mobile" ? theme.toggleMenu : null}>
                                                {link.title}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            <Button className="c-nav__text-button" link to="/build-your-fleet/" text="Quote request" onClick={theme.screenType === "mobile" ? theme.toggleMenu : null} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavSub;
