import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

const NavQuickLinks = () => {
    
    const quickLinksData = useStaticQuery(graphql`
        fragment navigationQuickContent on WORDPRESS_GlobalContent {
            ACFquickLinksBlockFields {
                quickLinks {
                  fontAwesomeIcon
                  pageLink {
                    title
                    url
                  }
                }
            }
        }

        query QuickLinkMenu {
            wordpress {
                EN: globalContents(where: {title: "Quick Links"}) {
                    nodes {
                         ...navigationQuickContent
                    }
                }
            }
        }
    `);

    let menu = quickLinksData.wordpress['EN'].nodes[0].ACFquickLinksBlockFields.quickLinks;

    return (
        <nav className="c-quick-nav" aria-label="Quick link menu">
            <ul aria-hidden="false">
                {menu.map(({ ...menuItem }, index) => {
                    return (
                        <li key={index}>
                                <Link
                                    className="c-quick-nav__link"
                                    to={menuItem.pageLink.url}
                                >
                                    <i className={"c-quick-nav__icon far " + menuItem.fontAwesomeIcon}></i>
                                    <span>{menuItem.pageLink.title}</span>
                                </Link>

                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavQuickLinks;